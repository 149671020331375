.datenschutzh{
    color:#00eeb9;

}
.datenschutzpNum{
  
    color:#f90e9b;
    color:#00eeb9;
    color:whitesmoke;
    color:#00eeb9;
}
.datenschutzp{ 
    color:whitesmoke;
    font-size: 2.5vw;

}
