.roadmapTxt{
    background-color: #090225;
    text-align: justify;
    margin-bottom: 5%;
    margin-right: 20%;
    margin-left: 20%;
   
    position: absolute;
  top: 15.5%;
  left: -3%;
  right:5%;
}
#roadmapTxt1{

    position: absolute;
  top: 15.9%;
  left: 23%;
  right:0%;
}
#roadmapTxt2{
   font-size: 1.7vw;
    position: absolute;
    padding: 1%;
  top: 15.5%;
  left: 0%;
  right:0%;
}
#roadmap-h1-buzzing{
    border-top: 3px solid #ee00d6 ;
    padding-left: 1.5%;
    padding-right: 1.5%;
      border-top-left-radius: 10%;
      border-top-right-radius: 10%;
    top:-2%;
    left: -20%;
    right:10%;
   padding-bottom: 1.5%;
   text-align: center;
   
}
#h1-dismissed{
    border-radius: 10%;
    border-top: 3px solid #ee00d6 ;
    background-color: #090225;
    position: absolute;
    top: 25.5%;
    left: 30%;
    right:13%;
    text-align: center;
    box-shadow: rgba(0, 255, 204, 0.278) 0 15px 30px -5px;
    box-sizing: border-box;
}
#roadmap-h1-4years{
    box-shadow: rgba(0, 255, 204, 0.278) 0 15px 30px -5px;
    box-sizing: border-box;
    border-radius: 10%;  
    border-top: 3px solid #ee00d6 ;
    padding-left: 1.5%;
    padding-right: 1.5%;
    background-color: #090225;
    position: absolute;
    top: 30%;
    left: 0%;
    right:40%;
    text-align: center;
}
#roadmap-iframe-container2{
   
    display: block;
    visibility: visible;
    position: absolute;
    left: 5%;
    top: 35.8%;
    z-index: 100;


}
.iframe-customize{
    position:absolute;
top:0;
left:30%
}
.h1-roadmap{
    box-shadow: rgba(0, 255, 204, 0.278) 0 15px 30px -5px;
  box-sizing: border-box;

    border-radius: 10%;
  
    text-align: center;
    position: absolute;
    background-color: #090225;
}
#roadmap-h1-realization{
    border-top: 3px solid #ee00d6 ;
    box-shadow: rgba(0, 255, 204, 0.278) 0 15px 30px -5px;
    box-sizing: border-box;
    left: 24%;
    padding-left: 1.5%;
    padding-right: 1.5%;
    right:24%;
    top: 49.4%;
    text-align: center;

}
#roadmap-h1-realization1{
  
  
    left: -15%;
    right:60%;
    top: 77.7%;
   

}
#roadmap-h1-realization2{
    padding-left: 1.5%;
    padding-right: 1.5%;
    border-top: 3px solid #ee00d6 ;
    /*border-left: 3px solid #00eeb9 ;*/
    left: 14%;
    right:23%;
    top: 56%;
    text-align: center;

}
#roadmap-h1-resurface{
    border-top: 3px solid #ee00d6 ;
    box-shadow: rgba(0, 255, 204, 0.278) 0 15px 30px -5px;
    box-sizing: border-box;
    padding-left: 1.5%;
    padding-right: 1.5%;
    left: 30%;
    right:18.5%;
    top: 64%;

}
#roadmap-h1-building{
    border-top: 3px solid #ee00d6 ;
    box-shadow: rgba(0, 255, 204, 0.278) 0 15px 30px -5px;
    box-sizing: border-box;
    text-align: center;
    padding-left: 1.5%;
    padding-right: 1.5%;
    left: 15%;
    right:30%;
    top: 70.5%;

}
#roadmapstartTxt{
    margin-left: 30%;
    margin-right: 30%;
    text-align: center;
   
}
#roadmap-h1-project{
    border-top: 3px solid #ee00d6 ;
    padding-left: 1.5%;
    padding-right: 1.5%;
    left: 20%;
    right:45%;
    top: 77.7%;
    box-shadow: rgba(0, 255, 204, 0.278) 0 15px 30px -5px;
    box-sizing: border-box;

}
#roadmap-h1-slideshow{
    border-top: 3px solid #ee00d6 ;
    box-shadow: rgba(0, 255, 204, 0.278) 0 15px 30px -5px;
    box-sizing: border-box;
  
    padding-left: 1.5%;
    padding-right: 1.5%;
    left: 26%;
    right:34%;
    top: 87.6%;

}
#roadmap-h1-persian{
    left: 30%;
    right:30%;
    top: 1%;
  
}


.roadmap-iframe-container{

       position: absolute;
       left: 4%;
       top: .4%;
      

}

.roadmap-h1-subthemes{
    background-color: #090225;
  

    text-align: left;
    margin-bottom: 5%;
    margin-top: 65%;
    margin-right: 30%;
    margin-left: 30%;
    position: absolute;
    top:1.1%;
    right:30%;
    left: -30%;

}
.roadmap-h1-leftAligned{

    text-align: left;
    margin-bottom: 2%;
    margin-top: 0%;
    margin-right: 10%;
    margin-left: 10%;
}
.roadmap-p-leftAligned{

    text-align: justify;
    margin-bottom: 5%;
    margin-top: 0%;
    margin-right: 10%;
    margin-left: 10%;
}
.roadmapSvgLine {
    overflow: hidden;
    position: relative;
    margin-left: 14.5%;
    
    width:75%;
    height:100%;
  }
  #pathroadmap{
    fill:none;
    stroke-width:30px;
    stroke:#00EEB9;
    stroke-linecap:round;
   /* stroke-dasharray: 10;*/
    stroke-dashoffset:0;
  }
  .p-shareMyDream{
    margin-bottom: 20%;
    margin-top: 4%; 
    margin-left: 30%;
    margin-right: 30%;
  


   
  }
  .h1-shareMyDream{
    margin-bottom: 0%;
    margin-top: 5%; 
    margin-left: 30%;
    margin-right: 30%;
   
text-align: center;
   
  }

  @media screen and (min-width: 920px) {
  
}