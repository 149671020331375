
@font-face {
  font-family:"Exo 2";
  src: url('../public/fonts/Exo_2/static/Exo2-Regular.ttf') format('truetype');
  /* Add other font properties (e.g., font-weight, font-style) if needed */
}
a{
  color:#00eeb9;
}
body {
    overflow-x: hidden;
    background-color:#090225;
    margin: 0;
  
    font-family: -apple-system, BlinkMacSystemFont, "Exo 2", 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
