
.pageintro-container{
    width:100%; 
    overflow: visible;
  
}
.pageintroh{

  opacity: 1;
  position: relative;
  margin-top: 0%;
  margin-left:30% ;
  margin-right:30% ;
  margin-bottom: 0;
  text-align: center;
}
.magicContainer-pi {
    overflow: visible;
    position: relative;
    margin-top: 0%;
    width:45%;
    margin-left: 27%;
    margin-right: 28%;
    margin-bottom: -5%;
    display: grid;
    grid-template-areas: "headerPI headerPI" "magic1PI magic2PI";
  }
  .grid-item-spice-pi {
    width:29%;
    justify-self: center;
    grid-area: headerPI;
    z-index: 100;
 
  }
  .grid-item-magicLeft-pi {
    width:130%;
    rotate: -6deg;
    justify-self: right;
    grid-area: magic1PI;
    transform-origin: right;
    margin-top: -15%;
    margin-right: -1vw;

  }
  .grid-item-magicRight-pi {
    width:130%;
    margin-left: 2.3vw;
    z-index: -1;
    justify-self: left;
    grid-area: magic2PI;
    transform-origin: left;
    margin-top: -44%;
rotate:6deg;
  }
  .pageintroH{
    opacity: 1;
    position: relative;
    top: 0%;
    margin-left:30% ;
    margin-right:30% ;
    text-align: center;
  }
  .pageintroP{
    position: relative;
    display: inline-block;
    margin-left:15% ;
    margin-right:15% ;
    margin-top:5% ;
    margin-bottom:0% ;
    text-transform: lowercase;
    text-align: justify;
    /*color: #fec125;*/
  }
  .pageintroH{
    opacity: 1;
    position: relative;
    top: 0%;
    margin-left:30% ;
    margin-right:30% ;
    text-align: center;
  }


  @media screen and (min-width: 1200px) {
  .pageintroP{
    position: relative;
    display: inline-block;
    margin-left:25% ;
    margin-right:25% ;
    margin-top:10% ;
    margin-bottom:0% ;
    text-transform: lowercase;
   
  
    /*color: #fec125;*/
  }
  .magicContainer-pi {
    overflow: visible;
    position: relative;
    margin-top: 0%;
    width:30%;
    margin-left: 35%;
    margin-right: 28%;
    margin-bottom: -9%;
    display: grid;
    grid-template-areas: "headerPI headerPI" "magic1PI magic2PI";
  }
}