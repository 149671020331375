.thePainting{
width:50%;
margin-left: 25%;

}


.ArtpageH{
   font-size: 4vw; 
   text-align: center;
   margin-left: 25%;
   margin-right: 25%;

}