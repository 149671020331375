.headerImage {
  position: absolute;
  display: inline-block;
  width: 20%;
  rotate: 0deg;
  left: 60%;
  top: 17%;
}
.linedown{
  position: relative;
  display: inline-block;
  rotate: 220deg;
  width: 90%;
margin-left: -46%;
margin-top: -23%;

}


.headersBubble {
  position: absolute;
  opacity: 1;
  width: 11%;
  top: 500%;
  left: -30%;
}
.dottedLine {
  border-top: 1px dotted #ffffff; /* Dotted line style */
  margin-left: 25%;
  margin-right: 25%;
  margin-top: 20px; /* Adjust the top margin as needed */
}
/*text in header*/
#PageHeaderTxtP{
    margin-top: 4%;
    margin-bottom: 10%;
  text-align: justify;  

}

.headerTextContainer {
  overflow: visible;
  position: relative;
  margin-top:20%;
  width: 100%;
}
.h1PageHeader {
  position: relative;
  margin-left: 21%;
  margin-right: 21%;
  margin-bottom: 0;
  text-transform: lowercase;
  text-align: center;
}
#quoteFrom{

  color:#00eeb9;
  color:#f90ee7;
  color: #fec125;
  color: white;
  color:#00eeb9;
  color: white;
  margin-top: -10%;
  margin-bottom: 5%;
  text-transform: lowercase;
}
.pageintroh{
  opacity: 1;
  position: relative;
  margin-top: 0%;
  margin-left:30% ;
  margin-right:30% ;
  margin-bottom: 0%;
  text-align: center;
}
.txtPageHeader {
  /*line-height: 200%;*/
  line-height: 4.8vw;
  position: relative;
  margin-left: 25%;
  margin-right: 25%;
}
.lineup {
  position: relative;
  display: inline-block;
  rotate: 30deg;
  width: 90%;
  margin-left: 52%;
  margin-bottom: -25%;
  top: 0%;
}

.headerContainer {
  position: relative;
  display: flex;
  z-index: 10000;
  overflow: visible;
}  
@media screen and (min-width: 920px) {

  .headerImage {
    position: absolute;
    display: inline-block;
    width: 15%;
    rotate: 0deg;
    left: 72%;
    top: 20%;
  }

  .headerGif {
    position: absolute;
    display: inline-block;
    rotate: 30deg;
    width: 60%;
    right: -30%;
    top: 0%;
  }
 
#PageHeaderTxtP{
  margin-top: 4%;
 
  margin-bottom: 10%;
text-align: justify;  
margin-bottom: 20%;
}
.txtPageHeader {

  line-height: 3vw;
position: relative;
margin-left: 15%;
margin-right: 38%;
}
.headerTextContainer {
position: relative;

width: 100%;
}

.h1PageHeader {
  position: relative;
  margin-left: 25%;
  margin-right: 25%;
  margin-bottom: 0;

  text-transform: lowercase;
  line-height: 4vw;
  text-align: center;
}
#quoteFrom{
  color: #fec125;
  color:#00eeb9;
  margin-top: -7%;
  text-transform: lowercase;
}
.lineup {
  position: relative;
  display: inline-block;
  rotate: 30deg;
  width: 70%;
  margin-left: 65%;
  margin-bottom: -25%;
  top: 0%;
}
.linedown{
  position: relative;
  display: inline-block;
  rotate: 220deg;
  width: 70%;
margin-left: -35%;
margin-top: -23%;

}

}

