

.TeamPage{
margin-top: 10%;
    position: relative;
    width: 100%;
    height: fit-content;
    
    
}
/*mapped content*/
.mappedContentContainer{
  
position: relative;
margin-left: 20%;
margin-right: 20%;
margin-bottom: 10%;
display: flex;
flex-direction:column;
align-items: center;

}
.mappedContentH{
   
    color:#f90ee7;
    color:#00eeb9;
    color: #fec125;
   
    display: inline-block;
    text-align: center;

}
.mappedContentTxt{
    
    display: inline-block;
   
    margin-left: 20%;
    margin-right: 20%;
  
}

.svg-icon3 {

    position: relative;
    margin-left: 10%;
    width: 11%; 
    transform-origin: right;
    transition: stroke 0.3s; /* Add transition for a smooth color change */
  }
  .svg-icon-path3 {
  
    stroke: white;
    stroke-width:7;
    }
    

    /*
.headerGif{
    
width: 50%;
position: relative;
display:inline-block;
margin-left: 25%;
margin-right: 25%;
z-index:100;

}
*/
.headerImg{
width:30%;
position: relative;
margin-left: 35%;
margin-right: 35%;
margin-bottom: 8%;
margin-top: 19%;
rotate: 0deg;

}

.contactTextContainer{
  position: relative;
  margin-top: 30%;
}
      
.pagetxt{
    text-transform: uppercase;
    position: relative;
    margin-left: 25%;
    margin-right: 13%;
    text-align: start;
    margin-top: -10%;
   
}
#pageHeaderH{
    position: relative;
    margin-left: 22%;
    margin-top: 0%;
}

.pagetxtLeft{
    position: relative;
    margin-left: 10%;
    margin-bottom:7% ;
    margin-top: 20%;
}
@media screen and (min-width: 920px) {
   
}