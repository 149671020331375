body {
  overflow-x: hidden;
  background-color: #090225;
}

div {
  /*border: 1px solid pink;*/
  min-height: auto;
  margin-bottom: 10%;
  z-index: 1;
  overflow: hidden;
}
h1 {
  color: white;
  font-family: "Exo 2", sans-serif;
  text-transform: uppercase;
}

.bufferForItIsDamnTime-beforClick {
  overflow: visible;
  position: relative;
  margin-top: 20%;
}
.bufferForItIsDamnTime-afterClick {
  overflow: visible;
  position: relative;
  margin-top: 75%;
}
#h1Intro {
  position: relative;
  margin-left: 3%;
  margin-right: 3%;
}
/*
.storytimeH{
  font-size: 2vw;
}
*/
.GreyToBackGround {
  position: absolute;
  top: 0;
  margin-left: 15%;
  margin-right: 15%;
}
.paintingNotArt {
  top: 9%;
  text-align: left;
}

p {
  color: white;
  font-size: var(--fs-400);
  line-height: var(--lh-400);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-align: justify;
}
.div0 {
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 1%;
  margin-bottom: 0%;
  position: relative;
  z-index: 10000;
  align-items: center;
  overflow: visible;
  text-align: center;
}
.ArtOrPaintingTitle {
  position: relative;
  margin-bottom: 7%;
  margin-right: 98%;
  margin-left: 18%;
  margin-top: -20%;
  /* 
    border: 2px solid yellow;
    font-size: 7vw;
    line-height:10vw ;
  */

  align-self: left;
}
.bubbles {
  position: absolute;
  opacity: 0;
  width: 30%;
  top: 600%;
  left: 60%;
}
.bubbleContainerGrey {
  display: grid;
  grid-template-columns: 100%;
  align-items: center;
  z-index: 10000000;
  position: absolute;
  overflow: visible;
  opacity: 1;
  top: 1000%;
  left: 30%;
  width: 60%;
}
.bubbleContainerGrey img {
  float: left;
  margin-top: -40%;
  margin-right: 40%;
  margin-left: 0%;
  width: 33%;
  z-index: -1;
  position: relative;
}
.bubbleContainerIntro {
  display: grid;
  grid-template-columns: 100%;
  align-items: center;
  z-index: 10;
  position: absolute;
  overflow: visible;
  opacity: 1;
  top: 2000%;
  left: 30%;
  width: 110%;
}
.emptyBubble {
  position: relative;
  opacity: 1;
  z-index: 1000000;
}
.colorSplash {
  margin-top: -34%;
  position: relative;
  z-index: 100000;
}

.color-bubbles {
  position: absolute;
  opacity: 1;
  width: 30%;
  top: 400%;
  left: 60%;
}
.div0 {
  margin-top: 5%;
  position: relative;
}
.div0 img {
  left: 50%;
  padding-top: 5%;
  padding-bottom: 5%;
  width: 20%;
}
.div0Container {
  min-height: 40%;
}
h1 {
  color: whitesmoke;
}
.parent {
  background-color: #090225;
  position: relative;
}
.divForLogoContainer {
  border: 2vw solid #0d043100;
  position: relative;
  z-index: 100;
  overflow: visible;
}
.divProjectSection {
  overflow: visible;
}
.div1 {
  position: auto;
  display: grid;
  grid-template-columns: 50%, 50%;
  z-index: 1;
}

.div1 img {
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 11vw;
  z-index: 10;
  position: relative;
}
.divGrey {
  overflow: visible;
  position: relative;
  margin-bottom: 35%;
  z-index: 1000;
}

.divGreyContent {
  width: 100%;
  margin-left: 0%;
  margin-right: 0%;
  position: relative;
  display: grid;
  grid-template-areas:
    "header header"
    "content content";
  align-content: start;
}

.GridItemGreyText {
  grid-area: content;
  justify-content: left;
  text-align: left;
  margin-left: 2%;
}

.GridItemGreyTitle {
  grid-area: header;
  text-align: start;
  margin-left: 2%;

  overflow: visible;
}
.ArtOrPaintingTitle {
  position: relative;
  margin-bottom: 8%;
  margin-right: 90%;
  margin-left: 5%;
  /* border: 2px solid yellow;*/
  font-size: 10vw;
  display: inline-block;
  align-self: left;
}
/*
.divGrey img {
  float: left;
  margin-top: -40%;
  margin-right: 40%;
  margin-left: 0%;
  width: 33%;
  z-index: -1;
  position: relative;
}*/
.greyToAnimate {
  position: absolute;
  opacity: 30%;
  width: 10%;
}
#greyUP {
  position: relative;
  width: 90%;
  margin-left: -50%;
  margin-top: 3%;
  margin-bottom: -30%;
  z-index: 10000;
}
#greyDown {
  width: 60%;
  rotate: 30deg;
  position: absolute;
  left: 22%;
  top: 2%;
  z-index: 1000;
}
#greyDown3 {
  width: 70%;
  rotate: 90deg;
  position: absolute;
  left: 86%;
  top: 100%;
  z-index: 1000;
}
#greyDown2 {
  position: absolute;
  left: -40%;
  top: -20%;
  width: 90%;
  z-index: 1;
}
#greySplashs {
  position: absolute;
  margin-top: 15%;
  margin-left: 0;
  z-index: 1;
}
.div2 img {
  position: relative;
  margin-top: -40%;
  margin-right: 0;
  padding-top: 5%;
  padding-bottom: 5%;
  right: 10%;
  opacity: 0;
  width: 31%;
  transform-origin: bottom;
  bottom: 0;

  z-index: 1;

  justify-self: end;
}
.div2 {
  margin-top: 5%;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  transform-origin: bottom;
  justify-content: end;
  z-index: 1;
  overflow: visible;
}

.bubbleContainerDiv2 {
  display: grid;
  grid-template-columns: 100%;
  align-items: center;
  z-index: 1000000;
  position: absolute;
  overflow: visible;
  opacity: 1;
  top: 200%;
  left: 50%;
  width: 60%;
}
.schirm {
  opacity: 0;
  left: auto;
  bottom: 0;
  scale: 3;
  transform-origin: bottom;
  z-index: 1;
}
.logo {
  position: absolute;
  left: auto;
  bottom: 0;
  scale: 1;
  transform-origin: center;
  z-index: 100000;
}
.imgDiv {
  min-height: auto;
  position: absolute;
}
.essential {
  height: 200px;
}
.logoContainer {
  display: grid;
  grid-template-columns: 100%;
  align-items: center;
  z-index: 1000;
  position: absolute;
  overflow: visible;
  opacity: 0;
}
.logoHandLeg {
}
#logoBeforPush {
  position: absolute;
  top: 40%;
  left: -40%;
  width: 180%;
}
#logoAfterPush {
  position: absolute;
  top: 50%;
  left: -44%;
  width: 175%;
}
#yourcolorsVid {
  width: 150%;
  display: inline-block;
  margin-left: -2%;
  margin-top: 0%;
}
#yourcolorsIMG1 {
  width: 160%;
  position: relative;
  display: inline-block;
  margin-left: -75%;
  margin-top: 20%;
}
#yourcolorsWords {
  width: 30%;
  position: absolute;
  display: inline-block;
  left: 22%;
  top: 8.2%;
  z-index: 10;
}


#journeyTxt {
  position: relative;
  margin-left: 27%;
  margin-right: 27%;
  padding-top: 25%;
}
.yourColorsDiv {
  position: relative;
  margin-top: 10%;
  margin-bottom: 40%;
}

#hand {
  opacity: 1;
  position: absolute;
  bottom: 0;
  width: 45%;
  left: 27%;
  top: -2%;
  stroke-width: 7;

  transform-origin: top;
  right: -1vh;
  z-index: 1;
}

.section1 img {
  width: 70%;
  transform-origin: left;
  left: -1vw;
}
.greyness {
  position: relative;

  display: grid;
  grid-template-columns: 1fr 1fr;
}
.greyness img {
  width: 90%;
}
#greyslow {
  scale: 1;
  transform-origin: bottom;
  bottom: 0;
}
#greyfast {
  scale: 1;
  transform-origin: top;
  top: 0;
}
.container img {
  width: 70%;
}
.container {
  position: relative;
  margin-left: 0;
  margin-right: 0;

  margin-bottom: 25%;
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  justify-items: start;
  z-index: 1;
  grid-column-gap: 1vw;
}
.container svg {
  width: 100%; /* Make the SVG take up the full width of its container */
  height: 100%; /* Make the SVG take up the full height of its container */
}

#rainbowStart {
}
#personNotTalking {
  opacity: 1;
  margin-top: 0%;
  right: 10vw;
}
.personNotTalking2 {
  rotate: -1deg;
  position: absolute;
  opacity: 1;
  right: 5vw;
  opacity: 1;
  margin-top: 160%;
  width: 57%;
  right: -80vw;
}
.personNotMoving2 {
  rotate: -1deg;
  position: absolute;
  opacity: 1;
  right: 5vw;
  opacity: 1;
  margin-top: 160%;
  width: 57%;
  right: 2vw;
}

.col1 {
  margin-left: 0;
  justify-self: start;
}
#spashStart {
  justify-self: end;
}
#colorSplashImg {
  width: 52%;
  opacity: 0;
  z-index: 1000;
  margin-left: 0;
  position: absolute;
  margin-top: 27%;
  rotate: -1deg;
  left: -5%;
}
.magicContainer {
  position: relative;
  margin-top: 27%;
  scale: 1.5;
  display: grid;
  grid-template-areas: "header header" "magic1 magic2";
}
.grid-item-spice {
  justify-self: center;
  grid-area: header;
  z-index: 100;
}
.grid-item-magicLeft {
  rotate: -6deg;
  justify-self: right;
  grid-area: magic1;
  scale: 4;
  transform-origin: right;
  margin-top: 2%;
  margin-right: -1vw;
}
.broDialog {
  color: white;
  rotate: -45deg;
  opacity: 0;
  z-index: 1000000;
  position: absolute;
  scale: 0.2;
  left: 70%;
  top: 60%;
}
.grid-item-magicRight {
  margin-left: 1.5vw;
  z-index: -1;
  rotate: 11deg;
  justify-self: left;
  grid-area: magic2;
  scale: 3.5;
  transform-origin: left;
  margin-top: -20%;
}

.logoDialog {
}
.nextSteps {
  position: relative;
  opacity: 1;
  width: 90%;
}

.div5 {
  /* border: 2px solid yellow;*/
  position: relative;
  margin-left: 5%;
  margin-right: 5%;
  text-align: left;
}

#logoWithHair {
  width: 15%;
  position: absolute;
  top: 23%;
  right: -160%;
  z-index: 100000;
}
#personNotTalking3 {
  rotate: -10deg;
  width: 35%;
  position: absolute;
  top: -52%;
  right: -160%;
  z-index: 10000000000;
}

.NextStepsContainer {
  /* border: 1px solid green;*/
  position: relative;
  padding-top: 0%;
  margin-top: 0;
  overflow: visible;
}
.ArtOrPainting {
  /* border: 1px solid yellow;*/

  padding-top: 5%;
  position: relative;
  overflow: visible;
  align-content: left;
}
#fightH {
  text-transform: uppercase;
  color: #00eeb9;
  margin-top: 3%;
  margin-bottom: 1%;
  font-size: 3vw;
  margin-right: 10%;
}
div {
  /* border: 2px solid yellow;*/
}
.storytime-title {
  position: relative;
  overflow: visible;
  margin-top: 15%;
  margin-bottom: -2%;
  /* border: 2px solid yellow;*/
}
.grid-container {
  display: grid;
  grid-template-columns: auto;
  gap: 2vw;
  position: relative;
  margin-top: 0;
  padding-top: 0;
  width: 72%;

  /* Set the height of the grid to 100% of the parent container */

  margin-top: 0;
}

.projectTxt {
  /* border: 2px solid yellow;*/

  position: relative;
  margin-left: 7%;
  margin-right: 10%;

  /* Set the height of the grid to 100% of the parent container */

  margin-top: 0%;
  margin-bottom: 0;
}

.first-column {
  grid-column: 1;
  height: auto;
}

.second-column {
  grid-column: 2;
  display: flex;
  flex-direction: column;
  align-self: start;
}

.sideText {
  position: absolute;
  left: 78%;
  display: block;
}

#firstSideText {
  top: -5%;
}
#secondSideText {
  top: 20%;
}
#thirdSideText {
  top: 32%;
}
#forthSideText {
  top: 36%;
  left: 79%;
  letter-spacing: 3vw;
}
#fifthSideText {
  top: 70%;
}

.div2Bubblecontainer {
  position: relative;
  min-height: 30vw;
  overflow: visible;
  z-index: 100000;
}
.bubbleDiv2 {
  display: grid;
  grid-template-columns: 100%;
  align-items: center;
  z-index: 10;
  position: absolute;
  overflow: visible;
  opacity: 1;
  top: 70%;
  left: 60%;
  width: 90%;
}

.emptyBubble2 {
  opacity: 1;
  width: 20%;
  z-index: 1000;
}
.colorSplash2 {
  margin-left: 4%;
  margin-top: -20%;
  width: 12%;
  opacity: 1;
}
#bubbleForLogo {
  opacity: 0;
  width: 15%;
  position: absolute;
  top: 2000%;
  z-index: 10000;

  /*

left:10%;
*/
  left: 80%;
}

.bubbleAttack {
  position: relative;
  overflow: visible;
}

.nextStepBodyContainer {
  position: relative;
  opacity: 0;
  overflow: visible;
  /*min-height: 500px;*/
}
.lastWordsTxt {
  position: absolute;
  text-align: center;
  top: 0%;
  left: 20%;
  right: 20%;
}
.secondGreyTxtp {
  position: relative;
  margin-left: 20%;
  margin-right: 20%;

  line-height: 3vw;
  margin-left: 20%;
  margin-right: 20%;
}
.greyTxt {
  margin-left: 0%;
  margin-right: 0%;
}

/*
.gridContainer{
  border: 2px solid red;
  margin-left: 7%;
  margin-right: 7%;
  margin-bottom: 70%;
  position: relative;
  display: grid;
  grid-template-areas:
  "leftSideHeader leftSideHeader"
  "rightSideTextader rightSideText";
  align-content: center;
  gap: 1.5vw;

}

.leftSideHeader{
grid-area: leftSideHeader;
position: relative;
font-size:8vw;
align-items: center;

padding-bottom: 0%;
text-transform: uppercase;



}
.rightSideText{
position: relative;
font-size: 3.5vw;
grid-area: rightSideText;
line-height: 7vw;

}

.containerTxt{
  margin-top: 0%;

  width:100%;
  display: flex;
 
}
*/

.gridContainer {
  /*  min-height: 100vw;*/

  position: relative;

  margin-left: 10%;
  margin-right: 10%;

  overflow: visible;
  margin-bottom: 50%;
}

.leftSideHeader {
  text-transform: uppercase;

  position: relative;
}

.containerTxt {
  margin-top: 0%;
  overflow: visible;
  width: 100%;
  display: flex;
}

#life {
  position: relative;
  rotate: -30deg;
  font-size: 15vw;
}
.lifeContainer {
  top: -9vw;
  left: 4.6%;
  position: absolute;

  overflow: visible;
}
#asA {
  opacity: 0;
  top: -8vw;
  right: 35vw;
  position: absolute;
  rotate: 0deg;
  font-size: 5.2vw;
  /* color:#00EEB9;*/
}
#painting {
  top: 2%;
  right: 5%;
  position: absolute;
  rotate: 0deg;
  font-size: 11vw;
}
/*paing not art section*/
.lifePaintGraphics {
  position: absolute;
  width: 35%;
  right: 0%;
}
.lamp {
  opacity: 1;
  /*
  top:-5vw;
  right:-18vw;
  */
  top: -26%;
  right: -14.9vw;
  position: absolute;
  rotate: 58deg;
  z-index: 1000;
  width: 70%;
}
#paintBrush {
  opacity: 1;
  /*right:99%;
  left:-8.2vw;
  top:-11.7%;
  */
  top: -92%;
  left: -8.7vw;
  rotate: -30deg;
  width: 130%;
  position: absolute;
}

#your {
  color: #00eeb9;
  position: relative;
  /*
  left:3.5%;
  top:5%;
  */
  font-size: 5vw;
  rotate: 60deg;
}
#magnet {
  opacity: 0;
  transform-origin: bottom;
  top: -14vw;
  right: 32vw;
  position: absolute;
  rotate: 5deg;
  z-index: 100;
  width: 40%;
}
#lampNotOn {
  z-index: 1000;
}
#lampOn {
}
#paintingImg {
  opacity: 0.2;
  right: 3%;
  top: -27%;
  width: 54%;
}

.secondGreyHeader {

  text-align: center;
  text-transform: uppercase;
  font-size: 11vw;
  line-height: 4vw;
  display: inline-block;
  margin-right: 2%;
  margin-left: 2%;
}
.secondGreyHeaderContainer {
  overflow: visible;
  position: relative;
  margin-left: 20%;
  margin-right: 15%;
  margin-bottom: 0;
}
#over {
  margin-left: 20%;
}
.greyDivSvgContainer {
  opacity: 0;
  position: relative;
  overflow: visible;
}
.greyDivSvg {
  opacity: 0;
  position: absolute;
  left: 45%;
  width: 2.3%;
  height: 100%;
  top: -13%;
}

.logoSvgLineContainer {
  position: relative;
  margin-top: 50%;
  height: 200%;
  margin-left: 15%;
  margin-right: 15%;
  overflow: visible;
}

.logoSvgLine {
  position: relative;
  margin-left: 35%;
  margin-top: 10%;
  width: 25%;
  height: 100%;
}
.greySvgArrow {
  position: relative;
  width: 20%;
}
.nextstepDiv {
  position: relative;
  margin-top: 100%;
  margin-bottom: 0%;
  overflow: visible;
  max-height: fit-content;
  /*border: 5px solid green;*/
}
.showMeActivationDiv {
  position: relative;
  overflow: visible;
  padding-bottom: 10%;
}

#showMeImgLine {
  position: absolute;

  top: 60%;
  right: 10%;

  width: 10%;
}
#showMeHeader {
  position: relative;
  margin-top: 20%;
  margin-left: 12%;
  margin-right: 12%;
  text-align: center;
}
#showMeText {
  position: relative;
  top: 10%;
  left: 40%;
  right: 10%;
}

#bobNextStep {
  position: relative;
  top: 0%;
  margin-left: 15%;
  margin-right: 15%;
  width: 70%;
}
.onePainting {
  display: inline-block;
  position: relative;
}
#oneInOnePainting {
  rotate: 0deg;
  padding-right: 0vw;
  padding-left: 0.5vw;
  margin-top: -10%;
  font-size: 10vw;
}
.campaignText {
  position: relative;
  margin-left: 30%;
  margin-right: 15%;
}
#campaignP {
  margin-left: 25%;
  margin-top: -15%;
  margin-bottom: 40%;
}
#campaignH {
  /*
  font-size: 5vw;
  line-height: 8.5vw;
  */
  text-align: left;
  margin-bottom: -25%;
  margin-right: 18%;
  margin-left: 27%;
}
.tooFastH {
  position: relative;
  margin-left: 38%;
  margin-right: 38%;
  text-align: justify;
  font-size: 2.1vw;
  margin-bottom: 20%;
  margin-top: 10%;
}
#whatIHave {
  position: relative;
  margin-top: 35%;
  text-align: center;

  margin-bottom: 10%;
  margin-left: 32%;
  margin-right: 32%;
}
#tooFast {
  margin-top: 35%;
  text-align: center;

  margin-bottom: 5%;
  margin-top: 60%;
}

#shit {
  text-align: center;

  margin-bottom: 5%;
}
.atATime {
  /* border: 2px solid aquamarine;*/
  width: 54%;
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin-top: -30%;
  margin-left: 4%;

  margin-bottom: 30%;
}
#crazyProjectH {
  position: absolute;
  font-size: 4vw;
  top: -37%;
  right: 0;
  left: -10%;
}
#sharedMemo {
  position: absolute;
  display: inline-block;
  text-align: left;
  top: 10%;
  left: 75%;
  top:8vw;
  font-size: 5vw;
}
#atATime {
  position: relative;
  font-size: 5vw;
  margin-top: 63%;
  left: -9%;
}
#h1Painting {
  position: relative;
  margin-top: 5%;
}
#teamworkIMG {
  position: absolute;
  left: 0%;
  right: 1%;
  width: 40%;
  top: 10%;
  opacity: 0;
}
#countless {
  position: absolute;
  display: inline-block;
  text-align: left;

  top: -13%;
  left: 25%;
  font-size: 5vw;
}
#memories {
  position: absolute;
  display: inline-block;
  text-align: left;

  top: 34%;
  left: 25%;
  font-size: 5vw;
}
.atATimeSlide {
  display: flex;
  position: relative;
  /*
  top: -10%;
  left: -188%;
  */
  left:-70%;
 
  z-index: 0;
  width: 100%;
  overflow: visible;
}
#nextButton {
  scale: 1;
  width: 4.5%;
  color: white;
  position: absolute;
  top: 0%;
  left: 40%;
  z-index: 11111;
}
#nextButton:hover {
  width: 4%;
}
.projectStatement {
  /* border:3px solid #00EEB9;*/
  position: absolute;
  z-index: 10000;
  display: flexbox;
  overflow: visible;
}
#AtATimeH {
  left: -120%;
  right: 50%;
  top: 4vw;
  padding-right: 100%;
  padding-left: 20%;
  font-size: 6vw;
  line-height: 9vw;
}
#countlessSharedMemoriesH {
  top: 4vw;
  right: 12%;
  left: -50%;
  padding-right: 80%;
  padding-left: 10%;
  font-size:4.5vw;
}
#oneCrazyProjectH {
  left: 80%;
  top: 4vw;
  font-size: 5vw;
  line-height: 9vw;
  padding-left: 10%;
}
#myImg{
position: relative;
margin-top: 14%;


left:100%;

width:100%;
/*margin-left:53.5%;*/
rotate:-48deg;
z-index: 3;
opacity: 1;
}
#greyInMyIntro{
  position: absolute;
  left:78%;
  top:55%;
  width:35%;
  z-index: -1;

 
}
#grey2InMyIntro{
  position: absolute;
  left:55%;
  top:34%;
  width:45%;
  z-index: -1;
  rotate: -40deg;
  opacity: .7;
}
#greyInMyIntrofront{
  position: absolute;
  left:87%;
  top:60%;
  width:37%;
  z-index: 0;
 rotate:-10deg;
 opacity: 0.5;
}
#sonne{
  position: absolute;
  top:5%;
  left:34%;
  width:110%;
  z-index: -1;
}

#myintroh1{
  position: absolute;
  top:30%;
  left:15%;
  right:70%;
  font-size: clamp(1.3rem, 5.8vw + 1rem,11rem);
}
.divMyIntro{
  width:100%;
  position: relative;
overflow: visible;
margin-top:-20%;
margin-bottom: 0;
}
.myintroTxts{
  margin-left: 15%;
  margin-right: 15%;
  margin-top: -10%;
  margin-bottom: 30%;
}
#greyTitle {
  display: inline-block;
  margin-left: 11%;
}
#onSvgtxt1 {
  background-color: #090225;
  position: absolute;
  top: 20%;
  left: 30%;
  right: 30%;
  z-index: 1;
  text-align: center;
}
#onSvgtxt2 {
  background-color: #090225;
  position: absolute;
  top: 75%;
  left: 30%;
  right: 30%;
  z-index: 1;
  text-align: center;
}
.passtilliosSvgContainer {
  position: relative;
}

@media screen and (min-width: 1000px) {
  /* Styles for screens with a minimum width of 1200 pixels */

  h1 {
    color: white;
    font-family: "Exo 2", sans-serif;
    z-index: 3000;
    text-transform: uppercase;
  }

  .ArtOrPaintingTitle {
    position: relative;
    margin-bottom: 5%;
    margin-right: 90%;
    margin-left: 5%;
    /* border: 2px solid yellow;*/
 
    display: inline-block;
    align-self: left;
  }

  .lastWordsTxt {
    position: absolute;
    text-align: center;
    top: 0%;
    left: 20%;
    right: 20%;
  }

  #life {
    position: relative;
    rotate: -30deg;
    font-size: 15vw;
  }

  #painting {
    top: 2%;
    right: 5%;
    position: absolute;
    rotate: 0deg;
    font-size: 11vw;
  }

  .secondGreyHeader {
    text-align: center;
    text-transform: uppercase;
    font-size: 6vw;
    line-height: 1.5;
    display: inline-block;
    margin-right: 2%;
    margin-left: 2%;
  }

  #showMeHeader {
    position: relative;
    margin-top: 20%;
    margin-left: 12%;
    margin-right: 12%;
    text-align: center;
  
  }

  #oneInOnePainting {
    rotate: 0deg;
    padding-right: 0vw;
    padding-left: 0.5vw;
    margin-top: -10%;
  
  }

  #campaignH {

    text-align: left;
    margin-bottom: -40%;
    margin-right: 40%;
    margin-left: 27%;
  }

  #whatIHave {
    position: relative;
    margin-top: 35%;
    text-align: center;
    margin-bottom: 10%;
    margin-left: 32%;
    margin-right: 32%;
  }

  #tooFast {
    text-align: center;
  
    margin-bottom: 10%;
    margin-top: 60%;
  }

  #shit {
    text-align: center;

    margin-bottom: 5%;
  }

 
  .greyTxt {
    margin-left: 10%;
    margin-right: 10%;

  }
  /*

 #crazyProjectH {
    position: relative;
    font-size: 4vw;
    top: -37%;
    right: 0;
    left: -10%;
  }

  #sharedMemo {
    position: absolute;
    display: inline-block;
    text-align: left;
    top: 10%;
    left: 75%;
    font-size: 5vw;
  }

  #atATime {
    position: relative;
    font-size: 5vw;
    margin-top: 63%;
    left: -9%;
  }

  #countless {
    position: absolute;
    display: inline-block;
    text-align: left;
    top: -13%;
    left: 25%;
    font-size: 5vw;
  }

  #memories {
    position: absolute;
    display: inline-block;
    text-align: left;
    top: 45%;
    left: 25%;
    font-size: 5vw;
  }

  #AtATimeH {
    padding-left: 10%;
    margin-right: 50%;
    margin-top: 40%;
    font-size: 7vw;
    line-height: 9vw;
  }

  #countlessSharedMemoriesH {
    padding-top: 32%;
    margin-right: 12%;
    margin-left: -40%;
    padding-right: 0%;
  }








#greyUP {
  position: absolute;
  opacity: 60%;
  width: 70%;
  left: -60%;
  top: 30%;
  z-index: 10000;
}
#greyDown {
  width: 50%;
  opacity: 50%;
  position: absolute;
  left: 80vw;
  top: 100%;
  z-index: 10000;
}
#greyDown2 {
  position: absolute;
  left: -30vw;
  top: 10%;
  width: 70%;
}
*/

  .GridItemGreyTitle {
    grid-area: header;
    text-align: start;
    margin-left: 10%;
    margin-right: 50%;
    overflow: visible;
  }
  .div2 img {
    width: 20%;
    padding: 11%;
  }
  .logoContainer {
    width: 10%;
  }
  .schirm {
    opacity: 0;
    left: auto;
    bottom: 0;
    scale: 2;
    transform-origin: bottom;
    z-index: 1;
  }
  .logo {
    position: absolute;
    left: auto;
    bottom: 0;
    scale: 1;
    transform-origin: center;
    z-index: 100000;
  }

  /*
.container img {
  width: 40%;
}
.container {
  position: relative;
  margin-top: -30%;
  margin-left: 0;
  margin-right: 0;
width:50%;
  margin-bottom: 25%;
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  justify-items: start;
  z-index: 1;
  grid-column-gap: 1vw;
  overflow: visible;
}
.container svg {
  width: 200%; 
  height: 200%; 
position: relative;
  margin-left: -0%;

}
#oneCrazyProjectH {
  padding-left: 30%;
  margin-top: 40%;
  font-size: 4vw;
  line-height: 9vw;
}

.divProjectSection {

  overflow: visible;

 margin-left:20% ;
}
.rainbow{

  left: -120%;
}
*/
  #h1Intro {
    position: relative;
    width: 70%;
    margin-top: 0%;
    margin-right: 15%;
    margin-left: 15%;
  
  }
 
  .projectTxt {
    /* border: 2px solid yellow;*/
    
    position: relative;
    margin-left: 20%;
    margin-right: 20%;

    /* 
  .storytime-title{

  position:relative;
  overflow: visible;
  z-index: inherit;
}
  border:10px solid red;
   Set the height of the grid to 100% of the parent container */

    margin-top: 2%;
    margin-bottom: 0;
  }

  .ArtOrPaintingTitle {
    position: relative;

    margin-right: 98%;
    margin-left: 18%;
    margin-top: -20%;
    /* border: 2px solid yellow;*/
 
    align-self: left;
  }
  #greyTitle {
    margin-left: 10%;
  }

  .secondGreyHeader {
    text-align: center;
    text-transform: uppercase;

    display: inline-block;
    margin-right: 2%;
    margin-left: 5%;
  }
  .secondGreyHeaderContainer {
    width: 40%;
    overflow: visible;
    position: relative;
    margin-left: 32%;
    margin-right: 25%;
    margin-bottom: 0;
  }
  .secondGreyTxtp {
    position: relative;
  }
 
  .bufferForItIsDamnTime-afterClick {
    margin-top: 0;
  }

  /*TODO: next step component*/
  .nextstepDiv {
    width: 60%;
    margin-left: 23%;
    position: relative;
    margin-top: 100%;
    margin-bottom: 0%;
    overflow: visible;
    max-height: fit-content;
    /*border: 5px solid green;*/
  }
}
