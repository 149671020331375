/*@import '../tailwind.config.js';*/ /* Adjust the path based on the actual location of tailwind.config.js */
:root{
  /* for h1s extra big */
  --fs-xl:clamp(1rem, 5vw + 1rem,10rem);
  --lh-xl:1.5;
    /* for h1 tags*/
    --fs-700:clamp(.25rem,1.4vw + 1rem,8rem);
    --lh-700:1.5;
  /* for h2 tags*/
  --fs-600:clamp(.75rem,1.2vw + 1rem,7rem);
  --lh-600:1.6;
  /* for h3 tags*/
  --fs-500:clamp(.5rem,.8vw + 1rem,6rem);
  --lh-500:2;
    /* for p tags*/
  --fs-400:clamp(.7rem,1.3vw + .5rem, 1.5rem);
  --lh-400:2;
  /* for p tags*/
  --fs-300:clamp(.6rem,.4vw + .5rem, 1.7rem);
  --lh-300:2;
  /* for p tags very small*/
  --fs-200:clamp(.4rem,.3vw + .4rem, 1rem);
  --lh-200:2;
}
h1 {

  color: white;
  font-family: "Exo 2", sans-serif;
  font-size:  var(--fs-700);
  line-height: var(--lh-700);
  text-transform: uppercase;
}
h2 {

  color: white;
  font-family: "Exo 2", sans-serif;
  font-size:  var(--fs-600);
  line-height: var(--lh-600);
  text-transform: uppercase;
}
h3 {

  color: white;
  font-family: "Exo 2", sans-serif;
  font-size: var(--fs-500);
  z-index: 3000;
  line-height: var(--lh-500);
  text-transform: uppercase;
}


.App {
  text-align: center;
}
.bg-img {
  /* The image used */
  background-color:white;
  min-height: 100vh;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  /* Needed to position the navbar */
  position: fixed;
}

/* Position the navbar container inside the image */
.container {
  
  position: absolute;
  margin: 20px;
  width: auto;
 
}

/* The navbar */
.topnav {
  overflow: hidden;
  background-color: none;
}

/* Navbar links */
.topnav a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 40px 16px;
  text-decoration: none;
  font-size: 17px;
}

.topnav a:hover {
  background-color: #ddd;
  color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
#img-logo{ 
  margin-top: 0px;
  padding: 0px,0px ;
  width: 80px;
}
#logo-a{
  padding: 10px 16px;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
