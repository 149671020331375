

input{
  opacity: 1;
}
.headerDiv{
  margin-top: 0%;
}


.svg-icon {overflow: visible;
  z-index: 1000 !important;
  position: absolute;
  left:56%;
  top:55%;
  width: 6vw; 
  transform-origin: right;
  transition: stroke 0.3s; /* Add transition for a smooth color change */
}


/* Style the SVG to be white when its parent has a 'clicked' class */
.clicked .svg-icon-path {
  stroke: white; /* Change the fill color to white */
  z-index: 1000 !important;
}
.svg-icon-path {
z-index: 1000 !important;
stroke:#090225;
stroke-width:7;
}
/*
@media (max-width: 600px) {
  
  .svg-icon {overflow: visible;
    z-index: 1000 !important;
 
  top:16vw;
   width: 22%; 
   transform-origin: right;
    transition: stroke 0.3s; 
  }
  
}
*/
.icon {
  position: absolute;
  top: 70%;
  left: 70%;
  transform: translateX(-50%) translateY(-50%);
}
.hamburger {
  opacity: 0;
  content: ' ';
  position: relative;
  width: 20px;
  border: 2px solid #090225;
  border-radius: 5px;
  transition: all 0.333333333333333s ease;
}
.hamburger:after,
.hamburger:before {
 
  content: ' ';
  position: absolute;
  left: 50%;
  width: 100%;
  border: 2px solid #090225;
  border-radius: 5px;
  transform: translateX(-50%);
  transition-property: top, bottom, transform;
  transition-duration: 1s, 1s, 0.25s;
}
.hamburger:after {
  top: -8px;
}
.hamburger:before {
  bottom: -8px;
}
.menu {

  pointer-events: none;
}
/*change the widths position: fixed;*/
.menu--circle {

  position: absolute;
  z-index: 100000000000;
  width: 60%;
  height:100%;
  background-color: #09022500;
border-bottom-right-radius: 70%;
  overflow: hidden;
}
.menu__active {
  position: relative;
 
}
.menu__toggle {
  z-index: 11;
  position: absolute;
  top: -15vw;
  left: -15vw;
  width: 30vw;
  height: 30vw;
  background-color: #ffffff;
  /*background-image: linear-gradient(225deg,#00EEB9,#F90EE7 89%);*/
  border-radius: 50%;
  transition: transform 1.3s;
  cursor: pointer;
  pointer-events: auto;
}

/*
.menu__listings {
  z-index: 10;
  position: absolute;
  top: -200px;
  left: -200px;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  transform: scale(0.1) rotate(150deg);
  transition: transform 1s;
}
*/
.menu__listings {
  z-index: 10;
  position: absolute;
  top: -30vw;
  left: -30vw;
  width: 60vw;
  height: 60vw;
  border-radius: 50%;
  transform: scale(0.1) rotate(150deg);
  transition: transform 1s;
}
.menu__arrow {
  visibility: hidden;
  position: absolute;
}
.menu__arrow input[type="radio"] {
  position: fixed;
  top: -99999px;

}
.menu__arrow ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.menu__arrow--top {
 
  top: 0;
  left: 33vw;
  z-index: 100000;
}
.menu__arrow--top .arrow {
  transform: rotate(-45deg);
}
.menu__arrow--left {
  top: 220px;
  left: 0.5em;
}
.menu__arrow--left .arrow {
  transform: rotate(-135deg);
}
.arrow {
  
  width: 4vw;
  height: 4vw;
  border-right: 6.666666666666667px solid #fff;
  border-top: 6.666666666666667px solid #fff;
  border-radius: 3px;
  transition: border-color 0.3s;
  cursor: pointer;
  pointer-events: auto;
}
.arrow:hover {
  border-color: #ff947f;
  transition: border-color 0.3s;
}
.words{
  font-size: 5vw;
}
.circle {
  position: relative;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;

  background-color: #fff;
  border-radius: 50%;
  transform: rotate(108deg);
  overflow: hidden;
  list-style: none;
}
.circle li {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 50%;
  transform-origin: 0 100%;
}
.circle li .placeholder {
  position: absolute;
  left: -100%;
  padding-top: 2vw;
  width: 200%;
  height: 200%;
  text-align: center;
  transform: skewY(54deg) rotate(18deg);
}
.circle li .placeholder .upside {
  transform: rotate(180deg);
}
.circle li .placeholder a {
  text-decoration: none;
  pointer-events: auto;
}
.circle li .placeholder button {
  pointer-events: auto;
}
.circle li .placeholder .button {
  font-size: 2vw;
text-transform:uppercase ;
  background-color: transparent;
  border: none;
  color: #090225;
  cursor: pointer;
}
.circle li:nth-child(1) {
  transform: rotate(0deg) skewY(-54deg);
  background-color: #fff;
}
.circle li:nth-child(2) {
  transform: rotate(36deg) skewY(-54deg);
  background-color: #fff;
}
.circle li:nth-child(3) {
  transform: rotate(72deg) skewY(-54deg);
  background-color: #fff;
}
.circle li:nth-child(4) {
  transform: rotate(108deg) skewY(-54deg);
  background-color: #fff;
}
.circle li:nth-child(5) {
  transform: rotate(144deg) skewY(-54deg);
  background-color: #fff;
}
.circle li:nth-child(6) {
  transform: rotate(180deg) skewY(-54deg);
  background-color: #fff;
}
.circle li:nth-child(7) {
  transform: rotate(216deg) skewY(-54deg);
  background-color: #fff;
}
.circle li:nth-child(8) {
  transform: rotate(252deg) skewY(-54deg);
  background-color: #fff;
}
.circle li:nth-child(9) {
  transform: rotate(288deg) skewY(-54deg);
  background-color: #fff;
}
.circle li:nth-child(10) {
  transform: rotate(324deg) skewY(-54deg);
  background-color: #fff;
}
#menu__active {
  position: fixed;
  top: -99999px;
  left: -99999px;
}
#menu__active:checked ~ label .menu__listings {
  transform: rotate(10deg) scale(1);
  transition: transform 1s;
}
#menu__active:checked ~ label .menu__toggle {
  background-color: #090225;
  transition: all 1s;
}
#menu__active:checked ~ label .hamburger {
  border-color: transparent;
  transition: border-color 0.333333333333333s;
}
#menu__active:checked ~ label .hamburger:after {
  top: -2px;
  border-color: #fff;
  transform: translateX(-50%) rotate(45deg);
  transition-property: top, transform;
  transition-duration: 0.25s, 1s;
}
#menu__active:checked ~ label .hamburger:before {
  bottom: -2px;
  border-color: #fff;
  transform: translateX(-50%) rotate(-45deg);
  transition-property: bottom, transform;
  transition-duration: 0.25s, 1s;
}
#menu__active:checked ~ label .button:hover {
  color: #F90EE7;
 
}

#menu__active:checked ~ label .menu__arrow {
  visibility: visible;
  transition: all 1s 1.111111111111111s;
}
#menu__active:checked ~ label .menu__arrow--top label {
  display: none;
}
#menu__active:checked ~ label .menu__arrow--top label[for="degree--up-0"] {
  display: block;
}
#menu__active:checked ~ label #degree--up-0:checked ~ .menu__listings {
  transform: rotate(116deg);
}
#menu__active:checked ~ label #degree--up-0:checked ~ .menu__arrow--top label {
  display: none;
}
#menu__active:checked ~ label #degree--up-0:checked ~ .menu__arrow--top label[for="degree--up-1"] {
  display: block;
}
#menu__active:checked ~ label #degree--up-1:checked ~ .menu__listings {
  transform: rotate(224deg);
}
#menu__active:checked ~ label #degree--up-1:checked ~ .menu__listings ~ .menu__arrow--top label {
  display: none;
}
#menu__active:checked ~ label #degree--up-1:checked ~ .menu__listings ~ .menu__arrow--top label[for="degree--up-2"] {
  display: block;
}

.customIconCss{

width: 5vw;
}
.radioHeader{
  opacity: 0!important;

}


