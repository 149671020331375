.h1-subthemes{
   

    text-align: center;
    margin-bottom: 5%;
    margin-top: 10%;
    margin-right: 30%;
    margin-left: 30%;
}
.h1-leftAligned{
  
    text-align: left;
    margin-bottom: 2%;
    margin-top: 0%;
    margin-right: 10%;
    margin-left: 10%;
}
.p-leftAligned{

    text-align: justify;
    margin-bottom: 5%;
    margin-top: 0%;
    margin-right: 10%;
    margin-left: 10%;
}
.txt{

}

.pages-imgs{
    width:40%;
    margin-left: 30%;
}
/*mapped content*/
.mappedContentContainer{
  
    position: relative;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 10%;
    display: flex;
    flex-direction:column;
    align-items: center;
    
    }
    .mappedContentH{
       
        color:#f90ee7;
        color:#00eeb9;
        color:#fec125;
        color:white;
       
     margin-left: 9%;
     margin-right: 9%;

        display: inline-block;
        text-align: center;
    
    }
    .mappedContentTxt{
      
       
        display: inline-block;
        text-align: justify;
      
    }
    .iframe-container {
        position: relative;
        display: inline-block;
        width: 100%;
        z-index: 100000000;
        margin-left: 5%;
        padding-bottom: 56.25%; /* 16:9 aspect ratio */

    }
      
      .iframe-container iframe {
        z-index: 100000000;
        position: absolute;
        top: 0;
        left: 7%;
        width: 70%;
        height: 70%;
        background-color:#f90ee7;
      }
      

@media screen and (min-width: 920px) {

    .h1-subthemes{
       
        text-align: center;
        margin-bottom: 5%;
        margin-top: 5%;
        margin-right: 30%;
        margin-left: 30%;
    }
.pages-imgs{
    margin-left: 40%;
    width:20%
}
.mappedContentTxt{
   
    display: inline-block;
  
  
}


}