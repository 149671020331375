/*@import '../tailwind.config.js';*/
/*

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.my-tailwind-component {
  @apply bg-gradient-to-r from-green-400 to-blue-500 p-8 !important;
}
*/
/* MyComponent.css */


.ContactPage {
  position: relative;
  width: 100%;
  height: fit-content;
  z-index: 1;
}
#e_mail {
  margin-top: 3%;
}
.InputLabelContainer{

  position: relative;
  overflow: visible;
  
}


/*
body {
    display: flex;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #f5f5f5;
  }
  img{
    display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0;
      padding-bottom: 0;
      width: 60%;
  }
  */
.btnEmailListContainer {
  min-height: 8vh;
  min-width: 102%;
  margin-bottom: 10vw;
  position: relative;
  width: 100%;
  overflow: visible;
  margin-top: -13%;
  margin-left: -2%;
}
/*
div{
  border:1px solid red;
}
*/
.form-control {
  margin-top: 3%;
  margin-bottom: 1%;
 
  color: #0d0431;
  opacity: 1;
  border: 4px solid white;
  display: inline-block;
  width: 65%;
  padding-top: 1%;
  padding-bottom: 2%;
  margin-left: 4%;
}
.form-control input{
  
  margin-right: 0%;
}
input:hover::placeholder {
  color: rgb(255, 255, 255);
}
input::placeholder {
  padding-left: 2%;

  color: rgb(255, 255, 255);
}
.nameInput::placeholder {
  /*color:    rgb(255, 0, 225);*/
  color: rgb(255, 255, 255);
}


/*
  h1{
    color:white;
    padding-top: 0;
    font-size: 5vw;
    padding-left: 2.7%;
  }
  h5{
    margin-left: auto;
    margin-right: auto;
  }
   border: .3vw solid #f90e9b64;
  */
.form-signin input {
  padding-left: 1%;
}
.form-signin input::placeholder {
  padding-left: 1%;
  padding-top: 2%;
}


.form-signin .form-floating:focus-within {
  z-index: 100;
}
.form-signin input[type="email"] {
  margin-top: 0%;
}

.form-signin input:hover {
  color: white;

  background-image: linear-gradient(
    102deg,
    #f90ee7,
    #5b42f3 40%,
    #00eeb9,
    orange
  );
}

.form-signin input[type="telNumber"] {
  color:#0d0431;
  background-image: linear-gradient(
    102deg,
    #f90ee7,
    #5b42f3 40%,
    #00eeb9,
    orange
  );

}
/*TODO*/
input:-webkit-autofill {
  appearance: none;
  color: white!important;
  background-image: linear-gradient(
    102deg,
    #f90ee7,
    #5b42f3 40%,
    #00eeb9,
    orange
  )!important;
  background-color:white!important;
  
}

.bd-placeholder-img {

  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.b-example-divider {
  width: 100%;
  height: 3rem;
  background-color: rgb(255, 255, 255);
  border: solid rgb(255, 255, 255);
  border-width: 1px 0;
  box-shadow: inset 0 0.5em 1.5em #0d0431, inset 0 0.125em 0.5em #0d0431;
}
.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}
.bi {
  vertical-align: -0.125em;
  fill: currentColor;
}
.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}
.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}


.btn {
  
  color: white;
}




.headerTxt {
  position: relative;
  display: inline-block;

  width: 20%;
  rotate: 0deg;
  margin-right: 0%;

  margin-bottom: 20%;
  margin-top: 20%;
}


.contactListVid {
  position: relative;
  width: 60%;
  margin-right: 20%;
  margin-left: 20%;
  margin-top: 0%;
  display: inline-block;
}
.contactListMsg{

 margin-left: 20%;
 margin-right: 10%;
 margin-top: 10%;
 margin-bottom: 1%;
 color:white;
}
.successContainer {
  position: relative;
}


/* Add the following styles to your existing CSS file */
/* header of form */
.svg-icon2 {
  position: absolute;
  top: 5%;
  left: 20%;
  width: 11%;
  transform-origin: center;
  transition: stroke 0.3s; /* Add transition for a smooth color change */
}
.svg-icon-path2 {
  stroke: white;
  stroke-width: 7;
}

.h1contact {
  position: relative;
  margin-left: 30%;
  margin-right: 20%;
  padding-left: 5%;
  margin-bottom: 0;
  line-height: 7.5vw;
}

/* end of header of form */


.form-signin {
  /*border: 0.1vw solid #00eeb9;*/
  width: 80%;
   /* Adjust the maximum width based on your design */
  margin-left: 7%;
  margin-right: 13%;
  overflow:"visible";


 
}

.mb-3 {
position: relative;
width: 100%;
margin-bottom: 2%;
overflow: visible;
}

.form-label {
  display: block;
  margin-top: 2%; 
  margin-bottom: 1%; 
  font-weight: bold;
  margin-left: 5%;
  display: inline-block;
  color: white;

}

.form-control {
  width: 100%;
  padding: 2%;
  margin-bottom: 2.5%; 
  box-sizing: border-box;
}

.btnEmailListContainer {
  width:100%;
  margin-top: 2%;
}
.conditionsTxt{
  width: 80%;
  display: "inline-block";
  margin-left: 20%;
  font-size: var(--fs-200);
  line-height: var(--lh-200);
} 
 

.errorP {
  color: #00eeb9;
  font-size: var(--fs-300);
  margin-left: 5%;
  margin-top: 0;
  margin-bottom: 0;
}


  @font-face {
    font-display: block;
    font-family: Roboto;
    src: url(https://assets.brevo.com/font/Roboto/Latin/normal/normal/7529907e9eaf8ebb5220c5f9850e3811.woff2) format("woff2"), url(https://assets.brevo.com/font/Roboto/Latin/normal/normal/25c678feafdc175a70922a116c9be3e7.woff) format("woff")
  }

  @font-face {
    font-display: fallback;
    font-family: Roboto;
    font-weight: 600;
    src: url(https://assets.brevo.com/font/Roboto/Latin/medium/normal/6e9caeeafb1f3491be3e32744bc30440.woff2) format("woff2"), url(https://assets.brevo.com/font/Roboto/Latin/medium/normal/71501f0d8d5aa95960f6475d5487d4c2.woff) format("woff")
  }

  @font-face {
    font-display: fallback;
    font-family: Roboto;
    font-weight: 700;
    src: url(https://assets.brevo.com/font/Roboto/Latin/bold/normal/3ef7cf158f310cf752d5ad08cd0e7e60.woff2) format("woff2"), url(https://assets.brevo.com/font/Roboto/Latin/bold/normal/ece3a1d82f18b60bcce0211725c476aa.woff) format("woff")
  }

  #sib-container input:-ms-input-placeholder {
    text-align: left;
    font-family: "Helvetica", sans-serif;
    color: #c0ccda;
  }

  #sib-container input::placeholder {
    text-align: left;
    font-family: "Helvetica", sans-serif;
    color: #c0ccda;
  }

  #sib-container textarea::placeholder {
    text-align: left;
    font-family: "Helvetica", sans-serif;
    color: #c0ccda;
  }

  #sib-container a {
    text-decoration: underline;
    color: #2BB2FC;
  }




@media screen and (min-width: 920px) {
  /*
  p {
    font-size: 1.5vw;
    line-height: 3vw;
  }
  */
/* header of form */
.svg-icon2 {
  position: absolute;
  top: 10%;
  left: 17%;
  width: 10%;
  transform-origin: center;
  transition: stroke 0.3s; /* Add transition for a smooth color change */
}
.svg-icon-path2 {
  stroke: white;
  stroke-width: 7;
}

.h1contact {
  position: relative;
  margin-left: 25%;
  margin-right: 25%;
  padding-left: 5%;
  margin-bottom: 0;

}

/* end of header of form */
 
  .form-signin {
    overflow: visible;
    border: 0.1vw solid #00eeb9;
    border-radius: 2%;
    width: 60%;
    margin-left: 15%;
    margin-right: 15%;
    padding-bottom: 5%;
    padding-top: 0%;
    padding-left: 7%;
    padding-right: 7%;
  }

  .form-label {
    display: block;
    margin-top: 2%; 
    margin-bottom: 1%; /* Adjust the margin as needed */
    font-weight: bold;
    margin-left: 5%;
    display: inline-block;
    color: white;
    
  }
}

